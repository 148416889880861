*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    background: #fff;
    font-family: 'Roboto';
}

.banner-img {
    display: grid;
    place-content: center;
    height: 100vh;
}

.login-style-section {
    height: 100vh;
    overflow: hidden;
}

.second-section {
    padding: 20px;
    height: 100vh;
    place-content: center;
    display: grid;
}

.outer-box-style {
    border-radius: 7px;
    border: solid 1px #f1f1f1;
    padding: 35px;
}

.outer-box-style1 {
    padding: 20px 35px 20px 35px;
    border-radius: 7px;
    border: solid 1px #f1f1f1;
}

.sign-up-style {
    color: #32393a !important;
    text-decoration: none !important;
    font-weight: 500;
}

.forgot-style {
    color: #32393a !important;
    text-decoration: none !important;
    font-weight: 400;
    font-size: 15px;
}

.breadcrumbs-style {
    color: #3097d5;
    text-decoration: none;
    font-size: 13px;
    font-weight: 500;
}

.or-style {
    position: absolute;
    top: -10px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 9;
}

.or-style::before {
    content: '';
    background-color: #fff;
    width: 70px;
    height: 20px;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
}

.css-1x2jons-MuiFormControlLabel-asterisk {
    display: none;
}

.agree-style {
    position: absolute;
    left: 29px;
    top: 9px;
    color: #32393a;
    font-size: 15px;
}


.custom-select-box {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #32393a !important;
    width: 100% !important;
}


/* .access-style {
    color: #3097d5;
    font-size: 13px;
    text-decoration: none;
} */

.MuiAlert-icon {
    color: #fff !important;
}


.css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel {
    display: block;
    margin-bottom: 0;
}

.css-levciy-MuiTablePagination-displayedRows {
    margin-bottom: 0;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    top: -7px !important;
}

.css-1ilptxz-MuiInputBase-root-MuiFilledInput-root {
    display: inline-flex !important;
    align-items: end !important;
    padding: 11px 11px 20px !important;
}

.css-18enwkh-MuiButtonBase-root-MuiButton-root:hover {
    color: #fff;
}

.css-y40t0m-MuiCardContent-root:last-child {
    padding-bottom: 15px !important;
}

.custom-datatable-class .MuiTableCell-head {
    padding: 0px 5px;
}

.custom-datatable-class .MuiTableCell-head>span>button {
    cursor: pointer;
    font-size: 14px !important;
    text-transform: capitalize !important;
}

.css-pdct74-MuiTablePagination-selectLabel {
    margin-top: 13px !important;
}

.sc-gswONk>button {
    margin-top: -3px !important;
}

/* .codeBlockView>.sc-gswONk {
    text-align: center !important;
} */
/*  */

.codeBlockView>.sc-gswONk>span>code {
    display: table-caption !important;
    white-space: normal !important;
    font-weight: 800 !important;
    /* color:rgb(48, 151, 213) !important; */
    font-size: 14px !important;
}

code {
    white-space: pre-wrap !important;
}

.active {
    color: #0d6efd !important;
}


/* In your global CSS file or a CSS module */
.custom-scrollbar::-webkit-scrollbar {
    width: 0px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #f7f6f6;
    border-radius: 20px;
}


.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #ddd transparent;
}